import React, { useState, useEffect } from 'react'
import '../app.scss'
import './contributors.scss'
import API from '../../backend/API'

const Contributors = () => {

    //State
    const [contributors, setContributors] = useState([])

    //Get all clients on load
    useEffect(() => {
        API.getContributors()
    }, [])

    return (
        <section className="flop__contributors">
            <div className="container">
                <div className="contributors__header">
                    <h1>Contributeurs du projet</h1>
                    <p>Retrouvez <a href="https://framagit.org/flopedt/FlOpEDT/-/raw/master/AUTHORS">ici</a> la liste de toutes les personnes ayant participé de près ou de loin au développement de l'application.</p>
                </div>
                <div className="contributors__tutorial">
                    <h1>Comment contribuer ?</h1>
                    <p>Vous pouvez cloner le <a href="https://framagit.org/flopedt/FlOpEDT">dépôt framagit du projet</a>, le forker, nous soumettre une Issue ou même une Merge Request. Vous trouverez toutes les infos utiles sur <a href="https://framagit.org/flopedt/FlOpEDT/-/wikis/home">ce wiki</a>.
                    
                    Vous pouvez également rejoindre <a href="https://framateam.org/signup_user_complete/?id=6bf6j8kzbpbfdye6uy3646p7ya&md=link&sbr=su">notre espace de discussion</a> framateam.</p>
                </div>
            </div>
        </section>
    )
}

export default Contributors