import React, { useState, useEffect } from 'react'
import '../app.scss'
import './support.scss'
import API from '../../backend/API'
import iut from '../../assets/images/logoiut.png'
import banquiz from '../../assets/images/lobo_banquiz.png'
import region from '../../assets/images/logo_nouvelle_aquitaine.png'

const Support = () => {

    return (
        <section className ="flop__support shadow">
            <div className="container">
                <div className="top__content">
                    <h2>Projet soutenu par :</h2>
                </div>
                <div className="row justify-content-center">
                <div>
                    <img src={region} alt="Région Nouvelle Aquitaine" />
                </div>
                <div>
                    <img src={banquiz} alt="La Banquiz" />
                </div>
                <div>
                    <img src={iut} alt="IUT de Blagnac" />
                </div>
                </div>
            </div>
        </section>
    )
}

export default Support